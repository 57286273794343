import { Row, Col, Typography } from "antd";
import { commonStyles } from "@datwyler/mfe-shared-components";
import { AlarmContainer } from "./styled";

const { Body14RegularTertiary, Body14RegularPrimary } = commonStyles;
const { Link } = Typography;

const Details = ({
  content,
}: {
  content: { label: string; value: string; showAsUrl?: boolean }[];
}) => {
  const handleVaildUrl = (url) => {
    try {
      const validUrl = new URL(url);
      window.open(validUrl, "_blank").focus();
    } catch (ex) {
      window.open(`//${url}`, "_blank").focus();
    }
  };

  return (
    <>
      {content.map(({ label, value, showAsUrl }) => (
        <AlarmContainer key={label}>
          <Row justify="space-between">
            <Col
              span={11}
              style={{ textAlign: "left", overflowWrap: "break-word" }}
            >
              <Body14RegularTertiary>{label}</Body14RegularTertiary>
            </Col>
            <Col
              span={11}
              style={{ textAlign: "right", overflowWrap: "break-word" }}
            >
              {showAsUrl ? (
                <Link onClick={() => handleVaildUrl(value)}>{value}</Link>
              ) : (
                <Body14RegularPrimary>{value}</Body14RegularPrimary>
              )}
            </Col>
          </Row>
        </AlarmContainer>
      ))}
    </>
  );
};

export default Details;
