import { Form } from "antd";
import { SingleSelect, commonStyles } from "@datwyler/mfe-shared-components";
import { LocationFormType } from "@types";

const { InputField } = commonStyles;

const LocationForm = ({ countryOptions, intl }: LocationFormType) => {
  return (
    <>
      <Form.Item
        name="location"
        label={intl.formatMessage({ id: "location_label" })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "location_validation_msg" }),
          },
        ]}
      >
        <InputField
          placeholder={intl.formatMessage({ id: "location_placeholder" })}
          variant="filled"
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldError }) => (
          <Form.Item
            name="country"
            label={intl.formatMessage({ id: "country" })}
            noStyle={false}
            shouldUpdate
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "country_validation_msg" }),
              },
            ]}
          >
            <SingleSelect
              style={{ width: "100%", height: "56px" }}
              placeholder={intl.formatMessage({ id: "country_placeholder" })}
              options={countryOptions}
              variant="filled"
              error={getFieldError("country")}
              popupMatchSelectWidth="100%"
              showSearch={true}
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        name="longitude"
        label={intl.formatMessage({ id: "longitude" })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "longitude_validation_msg" }),
          },
          {
            pattern: /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/,
            message: "Invalid",
          },
        ]}
      >
        <InputField
          placeholder={intl.formatMessage({ id: "longitude_placeholder" })}
          variant="filled"
        />
      </Form.Item>
      <Form.Item
        name="latitude"
        label={intl.formatMessage({ id: "latitude" })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "latitude_validation_msg" }),
          },
          {
            pattern: /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/,
            message: "Invalid",
          },
        ]}
      >
        <InputField
          placeholder={intl.formatMessage({ id: "latitude_placeholder" })}
          variant="filled"
        />
      </Form.Item>
    </>
  );
};

export default LocationForm;
