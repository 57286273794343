import { lodash as _ } from "@datwyler/mfe-shared-components";
import { Severity } from "@types";
export const translateDetailsTab = (locationDetails, siteDetails, devices) => {
  return [
    {
      label: "location",
      value: locationDetails?.name,
    },
    {
      label: "url",
      value: siteDetails?.url,
      showAsUrl: true,
    },
    {
      label: "longitude",
      value: locationDetails?.longitude,
    },
    {
      label: "Latitude",
      value: locationDetails?.latitude,
    },
    {
      label: "Number Of Devices",
      value: devices.length,
    },
  ];
};

export const getAlarmSeverityIcon = (severity: Severity) => {
  switch (severity) {
    case Severity.CRITICAL:
      return "error";
    case Severity.WARNING:
      return "warning";
    case Severity.INFORMATION:
      return "processing";
  }
};

export const getBadgeSeverity = (severity: Severity) => {
  switch (severity) {
    case Severity.CRITICAL:
      return "error";
    case Severity.WARNING:
      return "warning";
    case Severity.INFORMATION:
      return "processing";
    default:
      return "success";
  }
};

export const transformCountryOption = (
  countries: {
    name: string;
    id: string;
  }[]
) => {
  return countries.map(({ id, name }) => ({ label: name, value: id }));
};

export const checkSiteHasDevices = (sites = [], devices) => {
  const siteIds = sites.map((site) => site.id);
  return devices.some((device) => {
    const siteId = _.get(device, "site.id", null);
    return siteIds.includes(siteId);
  });
};

export const transformFilters = (filters) => {
  const queryFilters = [];
  Object.keys(filters).forEach((filterName) => {
    const values = filters[filterName];
    if (values) {
      if (Array.isArray(values)) {
        const query = values.map((value) => `${filterName}:${value}`).join("|");
        queryFilters.push(query);
      } else {
        queryFilters.push(`${filterName}:${values}`);
      }
    }
  });
  return queryFilters;
};

export const downloadAttachment = (
  { id, siteId, fileName },
  tenantId,
  uri,
  apiInstance,
  openNotification
) => {
  const url =
    uri + "/tenants/" + tenantId + "/sites/" + siteId + "/attachments/" + id;
  apiInstance
    .get(url, { responseType: "blob" })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch(() => {
      openNotification({
        type: "error",
        description: "Failed to download attachment.",
        placement: "topRight",
      });
    });
};

export const allowedExtensions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".xls",
  ".xlsx",
  ".doc",
  ".docx",
  ".csv",
];
