import { Space, Divider, Flex, Button, Col, Badge } from "antd";
import {
  SingleSelect,
  AddIcon,
  SingleArrowRightIcon,
  lodash as _,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import { LocationsListProps, ListItemType, LocationListHeader } from "@types";
import { StyledRow, ListContainer } from "./styled";
import { getBadgeSeverity } from "@helpers";

const {
  Body16RegularTertiary,
  Body16MediumPrimary,
  Caption12Secondary,
  Caption12Tertiary,
  StyledCard2: StyledCard,
} = commonStyles;

const Header = ({
  countryOptions,
  locationFilter,
  setLocationFilter,
  setComponentToShow,
  intl,
}: LocationListHeader) => {
  return (
    <Flex justify="space-between" align="center" style={{ padding: "16px" }}>
      <div>
        <Body16RegularTertiary>
          {intl.formatMessage({ id: "country" })}
        </Body16RegularTertiary>
        <SingleSelect
          options={countryOptions}
          variant="borderless"
          listHeight={280}
          value={locationFilter}
          popupMatchSelectWidth={200}
          style={{ width: "auto", maxWidth: 165, fontSize: "24px" }}
          onChange={setLocationFilter}
        />
      </div>
      <Button
        icon={<AddIcon style={{ height: 20, width: 20 }} />}
        type="primary"
        style={{ padding: "16px 10px", height: "40px" }}
        onClick={() => setComponentToShow("location-add")}
      >
        {intl.formatMessage({ id: "location_add" })}
      </Button>
    </Flex>
  );
};

const ListItem = ({
  location,
  setSelectedLocation,
  setComponentToShow,
}: ListItemType) => {
  const { name, country, sites, highestSeverity } = location;
  return (
    <StyledRow
      onClick={() => {
        setSelectedLocation(location);
        setComponentToShow("site-list");
      }}
    >
      <Col span={2}>
        <Badge
          status={getBadgeSeverity(highestSeverity)}
          styles={{ indicator: { width: 8, height: 8 } }}
        />
      </Col>
      <Col span={20}>
        <div>
          <Body16MediumPrimary>{name}</Body16MediumPrimary>
        </div>
        <div>
          <Caption12Secondary>{country?.name}</Caption12Secondary>
        </div>
        <div>
          <Space
            wrap
            split={<Divider type="vertical" />}
            style={{ columnGap: 0, rowGap: 0 }}
          >
            {(sites || []).map(({ name }) => (
              <Caption12Tertiary key={name}>{name}</Caption12Tertiary>
            ))}
          </Space>
        </div>
      </Col>
      <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
        <SingleArrowRightIcon style={{ height: 20, width: 20 }} />
      </Col>
    </StyledRow>
  );
};

const LocationList = ({
  locations,
  locationFilter,
  setLocationFilter,
  setSelectedLocation,
  setComponentToShow,
  intl,
}: LocationsListProps) => {
  const countryOptions = _.uniqBy(
    [
      { label: intl.formatMessage({ id: "all" }), value: "" },
      ...locations.map(({ country }) => {
        return { value: country.id, label: country.name };
      }),
    ],
    ({ value }) => value
  );

  const selectedLocations = locationFilter
    ? locations.filter(({ country }) => country.id === locationFilter)
    : locations;

  return (
    <StyledCard style={{ height: "500px" }}>
      <Header
        countryOptions={countryOptions}
        locationFilter={locationFilter}
        setLocationFilter={setLocationFilter}
        setComponentToShow={setComponentToShow}
        intl={intl}
      />
      <ListContainer>
        {selectedLocations.map((location, index) => {
          return (
            <ListItem
              key={index}
              location={location}
              setSelectedLocation={setSelectedLocation}
              setComponentToShow={setComponentToShow}
            />
          );
        })}
      </ListContainer>
    </StyledCard>
  );
};

export default LocationList;
