import { useEffect, useState } from "react";
import { Row, Col, Grid } from "antd";
import {
  Map,
  useIntl,
  styled,
  withPermission,
} from "@datwyler/mfe-shared-components";
import Location from "@components/Location";
import LocationsList from "@components/LocationsList";
import SiteList from "@components/SiteList";
import Breadcrumbs from "@components/Breadcrumb";
import SiteDetails from "@components/SiteDetails";
import useLocations from "@hooks/useLocations";
import { LocationsProps, LocationType, SiteType } from "@types";

const { useBreakpoint } = Grid;
const MapContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
`;

const Locations = ({ tenantId }: LocationsProps) => {
  const intl = useIntl();
  const screens = useBreakpoint();

  const {
    locations,
    siteDetails,
    alarmDetails,
    getSiteDetails,
    getAlarmDetails,
    loading,
  } = useLocations(tenantId);
  const [locationFilter, setLocationFilter] = useState("");
  const [selectedLocation, setSelectedLocation] =
    useState<LocationType | null>();
  const [selectedSite, setSelectedSite] = useState<SiteType | null>();
  const [componentToShow, setComponentToShow] = useState("");

  useEffect(() => {
    setSelectedLocation(null);
    setSelectedSite(null);
    setComponentToShow("");
  }, [locationFilter]);

  useEffect(() => {
    setSelectedSite(null);
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedSite) {
      getSiteDetails(selectedSite.id);
      getAlarmDetails(selectedSite.id);
    }
  }, [getSiteDetails, getAlarmDetails, selectedSite]);

  const onMarkerClick = (location) => {
    setSelectedLocation(location);
    setComponentToShow("site-list");
  };

  const ComponentSwitcher = () => {
    switch (componentToShow) {
      case "site-list":
        return (
          <SiteList
            location={selectedLocation}
            setSelectedSite={setSelectedSite}
            setComponentToShow={setComponentToShow}
            intl={intl}
          />
        );
      case "site-details":
        return (
          <SiteDetails
            tenantId={tenantId}
            siteDetails={{
              devices: siteDetails,
              site: selectedSite,
              location: selectedLocation,
            }}
            alarmDetails={alarmDetails}
            setSelectedSite={setSelectedSite}
            setComponentToShow={setComponentToShow}
            intl={intl}
            loading={loading}
          />
        );
      case "location-edit":
        return (
          <Location
            tenantId={tenantId}
            setComponentToShow={setComponentToShow}
            location={selectedLocation}
            mode="edit"
          />
        );
      case "location-add":
        return (
          <Location
            tenantId={tenantId}
            setComponentToShow={setComponentToShow}
            mode="add"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Breadcrumbs items={[{ title: "location" }]} />
      <div style={{ height: "100%", position: "relative" }}>
        {screens.lg && (
          <MapContainer>
            <Map locations={locations} onMarkerClick={onMarkerClick}></Map>
          </MapContainer>
        )}
        <Row gutter={[16, 16]} style={{ padding: "10px" }}>
          <Col lg={8} xs={componentToShow === "" ? 24 : 0}>
            <LocationsList
              locations={locations}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              setSelectedLocation={setSelectedLocation}
              setComponentToShow={setComponentToShow}
              intl={intl}
            />
          </Col>
          {componentToShow && (
            <Col lg={10} xs={24}>
              <ComponentSwitcher />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default withPermission("/site")(Locations);
