import { Spin, Flex, Badge, Row, Col, Typography } from "antd";
import { IntlShape } from "react-intl";
import {
  lodash as _,
  moment,
  commonStyles,
} from "@datwyler/mfe-shared-components";

import { getAlarmSeverityIcon } from "@helpers";
import { AlarmType } from "@types";
import { AlarmContainer } from "./styled";
import { useEffect, useState } from "react";

const { Body14RegularTertiary, Body16MediumPrimary, Body14RegularSecondary } =
  commonStyles;
const { Text } = Typography;

const Alarm = ({ alarms, intl }: { alarms: AlarmType[]; intl: IntlShape }) => {
  const [jsxElements, setJsxElements] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const alamrsGrouped = _.groupBy(alarms, ({ device: { name } }) => name);
    const keys = Object.keys(alamrsGrouped);
    const tempJsx = [];

    const processJsx = () => {
      keys.forEach((key) => {
        const alarmDetails = alamrsGrouped[key];
        tempJsx.push(
          <AlarmContainer key={key}>
            <Flex vertical gap={12}>
              <Body16MediumPrimary>{key}</Body16MediumPrimary>
              {alarmDetails.map((alarm, index) => {
                const attribute_name = _.get(
                  alarm,
                  "values.attribute_name",
                  ""
                );
                const severity = getAlarmSeverityIcon(
                  _.get(alarm, "severity", "error")
                );
                const timestamp = _.get(alarm, "time", "");
                const date = moment.unix(timestamp).format("MM/DD/YYYY");
                const time = moment.unix(timestamp).format("hh:mm:ss");
                const i18nName = intl.formatMessage({ id: attribute_name });
                return (
                  <Row key={index} wrap={false}>
                    <Col span={10} style={{ textAlign: "left" }}>
                      <Body14RegularSecondary>
                        <Text ellipsis={{ tooltip: i18nName }}>
                          <Badge status={severity} />
                          &nbsp;&nbsp; {i18nName}
                        </Text>
                      </Body14RegularSecondary>
                    </Col>
                    <Col span={6} style={{ textAlign: "center" }}>
                      <Body14RegularTertiary>{time}</Body14RegularTertiary>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <Body14RegularTertiary>{date}</Body14RegularTertiary>
                    </Col>
                  </Row>
                );
              })}
            </Flex>
          </AlarmContainer>
        );
      });
      setJsxElements(tempJsx);
      setLoading(false);
    };

    setTimeout(processJsx, 500);
  }, [alarms, intl]);

  if (loading)
    return (
      <Flex justify="center" style={{ height: "150px", marginTop: "50px" }}>
        <Spin />
      </Flex>
    );

  return <>{jsxElements}</>;
};

export default Alarm;
